import {
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserSession,
} from "amazon-cognito-identity-js";
import { Auth } from "aws-amplify";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { User } from "../../models/User";
import { UserAttributes } from "../../models/UserAttributes";
import storageType from "../../utils/storageService";
import { get } from "../Api/ApiFunctions";
import { useAuthContext } from "./AuthServiceProvider";
import { isTokenExpired } from "../../utils/helpers";

const AuthService = () => {
  const [auth, setAuth] = useAuthContext();
  const { t } = useTranslation();

  const hasToken: string | null = storageType(
    process.env.REACT_APP_STORAGE ? process.env.REACT_APP_STORAGE : "local"
  ).getItem(`${process.env.REACT_APP_NAME}-token`);

  return {
    isLoggedIn: !!hasToken,
    isSuperAdmin: false,
    user: {
      _id: "",
      email: "",
      firstName: "",
      lastName: "",
      profilePictureUrl: "",
      registrationDate: "",
      lastEditDate: "",
      role: "su",
    } as User,
    async getToken(): Promise<string> {
      try {
        const token = await this.oAuthTokenRefresh();
        if (token) {
          return token;
        }
        return "";
      } catch {
        storageType(
          process.env.REACT_APP_STORAGE
            ? process.env.REACT_APP_STORAGE
            : "local"
        ).removeItem("7circle-localization-platform-web-token");
        window.location.href = "/login";
        return "";
      }
    },
    async login(
      email: string,
      password: string
    ): Promise<CognitoUser | undefined> {
      try {
        const user: CognitoUser = await Auth.signIn(email, password);
        if (user.challengeName !== "NEW_PASSWORD_REQUIRED") {
          const session = await Auth.currentSession();
          if (session) {
            const token = session.getIdToken().getJwtToken();
            if (token) {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              if ((window as any).Cypress) {
                localStorage.setItem(
                  "7circle-localization-platform-web-token",
                  token
                );
              } else {
                storageType(
                  process.env.REACT_APP_STORAGE
                    ? process.env.REACT_APP_STORAGE
                    : "local"
                ).setItem(`${process.env.REACT_APP_NAME}-token`, token);
              }
              this.setIsLoggedIn(true);
              return user;
            }
          }
        } else if (user) {
          return user;
        }
      } catch {
        return undefined;
      }
      return undefined;
    },
    oAuthLogin(code: string): void {
      try {
        const body = new URLSearchParams();
        const searchParams = new URLSearchParams(document.location.search);
        const options = {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        };
        const state = searchParams.get("state");
        const codeVerifier = sessionStorage.getItem(`codeVerifier-${state}`);
        sessionStorage.removeItem(`codeVerifier-${state}`);
        body.set("code", code);
        if (
          process.env.REACT_APP_AMPLIFY_CONFIG_AUTH_OAUTH_GRANT_TYPE &&
          process.env.REACT_APP_AMPLIFY_CONFIG_AUTH_USERPOOLWEBCLIENTID &&
          process.env.REACT_APP_AMPLIFY_CONFIG_AUTH_OAUTH_REDIRECT_URL &&
          process.env.REACT_APP_AMPLIFY_CONFIG_AUTH_OAUTH_OAUTH_TOKEN_URL &&
          codeVerifier
        ) {
          body.set(
            "grant_type",
            process.env.REACT_APP_AMPLIFY_CONFIG_AUTH_OAUTH_GRANT_TYPE
          );
          body.set(
            "client_id",
            process.env.REACT_APP_AMPLIFY_CONFIG_AUTH_USERPOOLWEBCLIENTID
          );
          body.set(
            "redirect_uri",
            process.env.REACT_APP_AMPLIFY_CONFIG_AUTH_OAUTH_REDIRECT_URL
          );
          body.set("code_verifier", codeVerifier);
          body.set("client_secret", "");
          axios
            .post(
              process.env.REACT_APP_AMPLIFY_CONFIG_AUTH_OAUTH_OAUTH_TOKEN_URL,
              body.toString(),
              options
            )
            .then((response) => {
              const session = new CognitoUserSession({
                AccessToken: new CognitoAccessToken({
                  AccessToken: response.data.access_token,
                }),
                IdToken: new CognitoIdToken({
                  IdToken: response.data.id_token,
                }),
                RefreshToken: new CognitoRefreshToken({
                  RefreshToken: response.data.refresh_token,
                }),
              });

              if (session.isValid()) {
                const token = session.getIdToken().getJwtToken();
                const accessToken = session.getAccessToken().getJwtToken();
                const refreshToken = session.getRefreshToken().getToken();
                storageType(
                  process.env.REACT_APP_STORAGE
                    ? process.env.REACT_APP_STORAGE
                    : "local"
                ).setItem(`${process.env.REACT_APP_NAME}-token`, token);
                storageType(
                  process.env.REACT_APP_STORAGE
                    ? process.env.REACT_APP_STORAGE
                    : "local"
                ).setItem(
                  `${process.env.REACT_APP_NAME}-access-token`,
                  accessToken
                );
                storageType(
                  process.env.REACT_APP_STORAGE
                    ? process.env.REACT_APP_STORAGE
                    : "local"
                ).setItem(
                  `${process.env.REACT_APP_NAME}-refresh-token`,
                  refreshToken
                );
                storageType(
                  process.env.REACT_APP_STORAGE
                    ? process.env.REACT_APP_STORAGE
                    : "local"
                ).setItem("method", "third-party");
              }

              this.setIsLoggedIn(true);
            })
            .catch(() => {
              toast.error(t("errors.generic-error"));
            });
        }
      } catch {
        toast.error(t("errors.generic-error"));
      }
    },
    async oAuthTokenRefresh(): Promise<string | undefined> {
      try {
        const body = new URLSearchParams();
        const options = {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        };
        if (
          process.env.REACT_APP_AMPLIFY_CONFIG_AUTH_USERPOOLWEBCLIENTID &&
          process.env.REACT_APP_AMPLIFY_CONFIG_AUTH_OAUTH_OAUTH_TOKEN_URL
        ) {
          body.set("grant_type", "refresh_token");
          body.set(
            "client_id",
            process.env.REACT_APP_AMPLIFY_CONFIG_AUTH_USERPOOLWEBCLIENTID
          );
          body.set(
            "refresh_token",
            storageType(
              process.env.REACT_APP_STORAGE
                ? process.env.REACT_APP_STORAGE
                : "local"
            ).getItem(`${process.env.REACT_APP_NAME}-refresh-token`) || ""
          );
          const response = await axios.post(
            process.env.REACT_APP_AMPLIFY_CONFIG_AUTH_OAUTH_OAUTH_TOKEN_URL,
            body.toString(),
            options
          );
          const session = new CognitoUserSession({
            AccessToken: new CognitoAccessToken({
              AccessToken: response.data.access_token,
            }),
            IdToken: new CognitoIdToken({
              IdToken: response.data.id_token,
            }),
          });

          if (session.isValid()) {
            const token = session.getIdToken().getJwtToken();
            const accessToken = session.getAccessToken().getJwtToken();
            storageType(
              process.env.REACT_APP_STORAGE
                ? process.env.REACT_APP_STORAGE
                : "local"
            ).setItem(`${process.env.REACT_APP_NAME}-token`, token);
            storageType(
              process.env.REACT_APP_STORAGE
                ? process.env.REACT_APP_STORAGE
                : "local"
            ).setItem(
              `${process.env.REACT_APP_NAME}-access-token`,
              accessToken
            );
            return token;
          }
          return undefined;
        }
      } catch {
        return undefined;
      }
      return undefined;
    },
    async confirmNewPassword(
      user: CognitoUser,
      newPassword: string
    ): Promise<CognitoUser | undefined> {
      try {
        const result: CognitoUser = await Auth.completeNewPassword(
          user,
          newPassword
        );
        return result;
      } catch {
        return undefined;
      }
    },
    async resetPasswordRequest(email: string): Promise<void> {
      try {
        await Auth.forgotPassword(email);
      } catch {
        toast.error(t("errors.generic-error"));
      }
    },
    async submitResetPassword(
      email: string,
      code: string,
      newPassword: string
    ): Promise<void> {
      try {
        await Auth.forgotPasswordSubmit(email, code, newPassword);
      } catch {
        toast.error(t("errors.generic-error"));
      }
    },
    async changePassword(
      user: CognitoUser,
      oldPassword: string,
      newPassword: string
    ): Promise<void> {
      try {
        await Auth.changePassword(user, oldPassword, newPassword);
      } catch {
        toast.error(t("errors.generic-error"));
      }
    },
    async getCurrentUser(): Promise<CognitoUser | undefined> {
      try {
        const user = await Auth.currentAuthenticatedUser();
        return user;
      } catch {
        toast.error(t("errors.generic-error"));
      }
      return undefined;
    },
    async getAttributes(): Promise<CognitoUserAttribute[] | undefined> {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const attributes = await Auth.userAttributes(user);
        return attributes;
      } catch {
        toast.error(t("errors.generic-error"));
      }
      return undefined;
    },
    async oAuthAttributes(): Promise<UserAttributes | undefined> {
      try {
        const options = {
          headers: {
            Authorization: `Bearer ${
              isTokenExpired(
                storageType(
                  process.env.REACT_APP_STORAGE
                    ? process.env.REACT_APP_STORAGE
                    : "local"
                ).getItem(`${process.env.REACT_APP_NAME}-access-token`)
              )
                ? await this.getToken()
                : storageType(
                    process.env.REACT_APP_STORAGE
                      ? process.env.REACT_APP_STORAGE
                      : "local"
                  ).getItem(`${process.env.REACT_APP_NAME}-access-token`)
            }`,
          },
        };
        if (process.env.REACT_APP_AMPLIFY_CONFIG_AUTH_OAUTH_USER_INFO) {
          const response = await axios.get(
            process.env.REACT_APP_AMPLIFY_CONFIG_AUTH_OAUTH_USER_INFO,
            options
          );
          const attributes: UserAttributes = response.data;
          return attributes;
        }
      } catch {
        try {
          const options = {
            headers: {
              Authorization: `Bearer ${
                isTokenExpired(
                  storageType(
                    process.env.REACT_APP_STORAGE
                      ? process.env.REACT_APP_STORAGE
                      : "local"
                  ).getItem(`${process.env.REACT_APP_NAME}-access-token`)
                )
                  ? await this.getToken()
                  : storageType(
                      process.env.REACT_APP_STORAGE
                        ? process.env.REACT_APP_STORAGE
                        : "local"
                    ).getItem(`${process.env.REACT_APP_NAME}-access-token`)
              }`,
            },
          };
          if (process.env.REACT_APP_AMPLIFY_CONFIG_AUTH_OAUTH_USER_INFO) {
            const response = await axios.get(
              process.env.REACT_APP_AMPLIFY_CONFIG_AUTH_OAUTH_USER_INFO,
              options
            );
            const attributes: UserAttributes = response.data;
            return attributes;
          }
        } catch {
          toast.error(t("errors.generic-error"));
        }
      }
      return undefined;
    },
    async logout(): Promise<void> {
      const s = await Auth.currentAuthenticatedUser();
      if (s) {
        await Auth.signOut({ global: true });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if ((window as any).Cypress) {
          localStorage.removeItem("proke-backoffice-token");
        } else {
          storageType(
            process.env.REACT_APP_STORAGE
              ? process.env.REACT_APP_STORAGE
              : "local"
          ).removeItem(`${process.env.REACT_APP_NAME}-token`);
        }
        this.setIsLoggedIn(false);
      }
    },
    oAuthLogout(): void {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if ((window as any).Cypress) {
        localStorage.removeItem("proke-backoffice-token");
        localStorage.removeItem("proke-backoffice-access-token");
        localStorage.removeItem("proke-backoffice-refresh-token");
      } else {
        storageType(
          process.env.REACT_APP_STORAGE
            ? process.env.REACT_APP_STORAGE
            : "local"
        ).removeItem(`${process.env.REACT_APP_NAME}-token`);
        storageType(
          process.env.REACT_APP_STORAGE
            ? process.env.REACT_APP_STORAGE
            : "local"
        ).removeItem(`${process.env.REACT_APP_NAME}-access-token`);
        storageType(
          process.env.REACT_APP_STORAGE
            ? process.env.REACT_APP_STORAGE
            : "local"
        ).removeItem(`${process.env.REACT_APP_NAME}-refresh-token`);
        storageType(
          process.env.REACT_APP_STORAGE
            ? process.env.REACT_APP_STORAGE
            : "local"
        ).removeItem("method");
      }
      this.setIsLoggedIn(false);
    },
    setIsLoggedIn(state: boolean) {
      this.isLoggedIn = state;
      setAuth(this);
    },
    getIsLoggedIn() {
      return auth.isLoggedIn;
    },
    async getUser(): Promise<User | null> {
      try {
        const user = await get<User>("/users/self");
        if (user) {
          this.user = user.data;
          this.isSuperAdmin = user.data.role === "sa";
          setAuth(this);
          return user.data;
        }
        return {
          _id: "",
          email: "",
          firstName: "",
          lastName: "",
          profilePictureUrl: "",
          registrationDate: "",
          lastEditDate: "",
          role: "su",
        };
      } catch {
        return {
          _id: "",
          email: "",
          firstName: "",
          lastName: "",
          profilePictureUrl: "",
          registrationDate: "",
          lastEditDate: "",
          role: "su",
        };
      }
    },
    getIsSuperAdmin() {
      return auth.isSuperAdmin;
    },
  };
};

export default AuthService;
